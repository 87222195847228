//Toolbox with drag and drop customisation

import { fadeSmoother, stringToBoolean } from '../../helpers/helpers';

import Cookies from 'js-cookie';
import { convertSavedToolbox } from './convertSavedToolbox';
import { setupCustomSortableHandlers } from './sortableKeyboard';
import { toolboxMove } from './movers';
import userStateManager from '../../helpers/stateManager';

export class Toolbox {
    constructor(toolboxUserState, allItems, strToolboxKey, showTooltips, $el) {
        //Data
        this.savedItems = toolboxUserState.ToolboxItems;
        this.allItems = allItems;
        this.strToolboxKey = strToolboxKey;
        this.showTooltips = showTooltips;

        //Element references
        this.$el = $el;
        this.$tipToolbox = this.$el.parents('.toolbox-panel').find('.callout-box--info');
        this.$btnAdd = this.$el.find('[data-toolbox-add-trigger]');
        this.$btnDone = this.$el.find('[data-toolbox-done-trigger]');
        this.$tipCustomise = this.$el.parents('.toolbox-panel').find('.callout-box--customise');
        this.$btnCustomise = this.$el.parents('.toolbox-panel').find('[data-toolbox-customise-trigger]');
        this.$cbShowTooltips = this.$el.find('.dfes-checkbox');
        this.$btnAddWrap = this.$el.find('.toolbox__add');
        this.$remaining = this.$el.find('.toolbox__remaining');
        this.$lower = this.$el.find('.toolbox__lower'); //done button container
        this.$loader = this.$el.find('.loader-2');
        this.modalId = this.$el.parents('.modal').attr ('id');
        this.isModalVersion = this.$el.parents('.modal').length > 0 && window.innerWidth > 1200; //Force customising state to persist

        //Message defaults
        this.noItemsMessage = 'Oops, it looks like you have no items in your toolbox. Please add at least one link added to continue.';
        this.tipToolboxDefault = this.$tipToolbox.find('.callout-box__content').html();
        this.tipCustomiseDefault = this.$tipCustomise.find('.callout-box__content').html();

        //Classes
        this.classMain = 'sortable-' + this.strToolboxKey;
        this.classRemaining = 'sortable-remaining-' + this.strToolboxKey;
        this.classGroup = 'sortable-group-' + this.strToolboxKey; //Joiner for drag and drop

        //Intro toolbox callout
        const strTipToolboxClosed =
            Cookies.get(this.strToolboxKey + 'tipToolboxClosed') !== 'true'
                ? 'false'
                : Cookies.get(this.strToolboxKey + 'tipToolboxClosed');
        this.tipToolboxClosed = stringToBoolean(strTipToolboxClosed);
        //Customise callout state
        const strTipCustomiseClosed =
            Cookies.get(this.strToolboxKey + 'tipCustomiseClosed') !== 'true'
                ? 'false'
                : Cookies.get(this.strToolboxKey + 'tipCustomiseClosed');
        this.tipCustomiseClosed = stringToBoolean(strTipCustomiseClosed);

        this.init();
    }

    init() {
        try {
            this.render();
            this.setupCalloutEvents(); //ToDo: Global for entire site?
            this.setupCustomiseEvents();
            this.setupTooltip();
            this.setupTooltipEvents();

            window.addEventListener('userStateUpdated', this.onUserStateUpdate);
        } catch (err) {
            console.error('A toolbox setup was called but there was a problem: ', err);
            if (this.$el.length > 0) {
                this.$el.html(
                    '<p style="text-align: center;">Oops, there was a problem setting up your toolbox. Please try again later.</p>'
                );
            }
        }
    }

    getToolboxItemHtml(item) {
        const target =
            item.PageUrl !== null && typeof item.PageUrl !== 'undefined' && item.PageUrl.indexOf('/systems/') > 0 ? 'target="_blank"' : '';

        return `
            <li data-id=${item.Id} data-tooltip="${item.Description}" data-toggle="tooltip" data-placement="top" title="${item.Description}">
                <button class="btn-add-remove" tabindex="-1" aria-hidden="true" aria-label="Add or remove item"></button>
                <div>
                    <a class="content" href="${item.PageUrl}" ${target}>
                        <img class="dfes-svg-icon" src="/assets/static/icons/${item.IconClass}.svg" alt=""/>
                        <div class="h3 title">${item.Name}</div>
                    </a>
                    <button class="info">
                        <i class="fa fa-info" aria-hidden="true"><span class="sr-only">information guide</span></i>
                    </button>
                </div>
            </li>
        `;
    }

    getPopoverHtml(tooltip) {
        return `
            <button class="btn-close">
                <span class="sr-only">Close</span>
            </button>
            <div class="popover__controls">
                <button class="btn" data-toolbox-remove><i class="fa fa-trash"></i>
                    Delete
                </button>
                <div class="hidden-sm hidden-md">
                    <button class="btn" data-toolbox-up><i class="fa fa-angle-double-up"></i>Move up</button>
                    <button class="btn" data-toolbox-down><i class="fa fa-angle-double-down"></i>Move down</button>
                </div>
                <div class="visible-sm-block visible-md-block">
                    <button class="btn" data-toolbox-up><i class="fa fa-angle-double-left"></i>Move left</button>
                    <button class="btn" data-toolbox-down><i class="fa fa-angle-double-right"></i>Move right</button>
                </div>
                
            </div>
            <div class="popover__text">
                ${tooltip}
            </di>
        `;
    }

    onUserStateUpdate = (ev) => {
        // Called via event listener, updates content, re-renders
        // Home page has multiple places to update...
        // Check if items need updating - avoid the animation if possible. Check the DOM vs userstate that was retrieved
        setTimeout(() => {
            try {
                var updateRequired;
                var currentItems = this.getCurrentRenderedItems();
                var savedUserState = JSON.parse(sessionStorage.getItem('userState'));

                //Length check
                if (savedUserState[this.strToolboxKey].ToolboxItems.length !== currentItems.length) {
                    updateRequired = true;
                } else {
                    //Match ids
                    updateRequired = savedUserState[this.strToolboxKey].ToolboxItems.some((item, index) => {
                        try {
                            return item.Id !== currentItems[index].Id;
                        } catch (error) {
                            console.warn('Error occured during an update compare: ');
                            console.log(error);
                            console.log('Toolbox Panel container:');
                            console.log(this.$el.parent().parent().attr('class'));
                            console.log('user state:');
                            console.log(savedUserState);
                            console.log('current items:');
                            console.log(currentItems);
                        }
                    });
                }
                if (updateRequired) {
                    this.savedItems = savedUserState[this.strToolboxKey].ToolboxItems;
                    this.render();
                }
                //Always update tooltips as the animation is not an issue
                this.showTooltips = savedUserState.ShowTooltips;
                this.setupTooltip();
            } catch (err) {
                console.warn('A toolbox update error occured: ' + err);
                console.log(err);
                if (this.$el.length > 0) {
                    this.$el.html(
                        '<p style="text-align: center;">Oops, there was a problem setting up your toolbox. Please try again later.</p>'
                    );
                }
            }
        }, 800);
    };

    render() {
        var itemsSeparated = convertSavedToolbox(this.savedItems, this.allItems);
        var items = itemsSeparated.items;
        var remainingItems = itemsSeparated.remaining;
        var itemMarkup = '';
        var remainingMarkup = '';

        //Cleanup any existing lists, in case of update
        this.$el.find('ul').remove();

        //Main list
        itemMarkup = items.map((item) => this.getToolboxItemHtml(item));
        var $mainList = $('<ul>', {
            class: this.classMain + ' ' + this.classGroup,
        });
        $mainList.html(itemMarkup);

        //Remaining list
        remainingMarkup = remainingItems.map((item) => this.getToolboxItemHtml(item));
        var $remainingList = $('<ul>', {
            class: this.classRemaining + ' ' + this.classGroup,
        });
        $remainingList.html(remainingMarkup);

        //Update markup smoothly
        this.$loader
            .fadeOut(300)
            .promise()
            .done(() => {
                this.$el.prepend($mainList);
                this.$remaining.append($remainingList);
                this.$btnCustomise.fadeIn();

                if (!this.tipToolboxClosed) {
                    fadeSmoother.fadeIn(this.$tipToolbox);
                }

                //Initilise sortable but start disabled
                //http://api.jqueryui.com/sortable/
                this.$el.find('ul').sortable({
                    connectWith: '.' + this.classGroup,
                    placeholder: 'sortable-placeholder',
                    stop: this.handleSortStop,
                });

                //Init custom sortable event handlers (keyboard and mobile buttons)
                setupCustomSortableHandlers(this.$el);

                this.setupTooltips();

                //Init modal mode?
                if (this.isModalVersion) {
                    // Editable modal toolboxes excep support
                    // if ( this.modalId === 'supportModal' ) {
                    //     this.disableSorting();
                    // } else {
                    //     this.handleCustomiseClick();
                    // }

                    // Disable edit mode to all modal toolboxes
                    this.disableSorting();
                } else {
                    //If not modal mode, disable sorting.
                    // Sortable takes a while to set the tab index so use a timeout
                    setTimeout(() => {
                        this.disableSorting();
                    }, 200);
                }

                //Set up listener to disable links while customising is active
                this.$el.find('a').on('click', (ev) => {
                    if ($(this.$el).hasClass('-customising')) {
                        ev.preventDefault();
                        $(ev.target).parents('li').first().focus();
                    }
                });
            });
    }

    handleSortStop = (evt, ui) => {
        const item = ui.item;

        //Clean up any popovers and tooltips
        if (window.innerWidth > 1200) {
            $('[data-toggle="tooltip"]').tooltip('hide');
        } else {
            $('.info').popover('hide');
        }
        $(item).focus();
    };

    setupTooltips() {
        //Setup tooltips or popovers for mobile - after render
        if (window.innerWidth > 1200) {
            this.$el.find('[data-toggle="tooltip"]').tooltip();
        } else {
            //Setup mobile tooltips versions as popovers
            const self = this;

            this.$el.find('.info').each(function () {
                const tooltipText = $(this).parents('li').data('tooltip');
                const html = self.getPopoverHtml(tooltipText);

                $(this)
                    .popover({
                        trigger: 'manual',
                        placement: 'bottom',
                        content: html,
                        html: true,
                    })
                    .on('click', function (e) {
                        e.preventDefault();
                        //Turn off other popovers, toggle this, reset position
                        $('.info').not(this).popover('hide');
                        $(this).popover('toggle');
                    });

                $(this).on('shown.bs.popover', function (e) {
                    console.log('on shown popover');
                    const bounding = $(this).next('.popover')[0].getBoundingClientRect();
                    if (bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
                        console.log('popover outside viewport');
                        $(this)
                            .next('.popover')
                            .addClass('top')
                            .removeClass('bottom')
                            .css('transform', 'translateY(-100%)')
                            .css('top', '0');
                    }
                });

                $(this).on('inserted.bs.popover', function (e) {
                    console.log('on inserted popover');
                    //Setup event listeners for controls in popover
                    const $remove = $(this).next('.popover').find('[data-toolbox-remove]');
                    const $up = $(this).next('.popover').find('[data-toolbox-up]');
                    const $down = $(this).next('.popover').find('[data-toolbox-down]');

                    $remove.click(function () {
                        toolboxMove.toggle($remove.parents('li').first());
                    });
                    $up.click(function () {
                        toolboxMove.left($up.parents('li').first());
                    });
                    $down.click(function () {
                        toolboxMove.right($down.parents('li').first());
                    });
                });
            });

            //Close button
            $(document).on('click', '.popover .btn-close', function () {
                $(this).parents('.popover').popover('hide');
            });
        }
    }

    setupCalloutEvents() {
        this.$tipToolbox.find('.btn-close').on('click', (ev) => {
            this.tipToolboxClosed = true;
            Cookies.set(this.strToolboxKey + 'tipToolboxClosed', this.tipToolboxClosed);
        });

        this.$tipCustomise.find('.btn-close').on('click', (ev) => {
            this.tipCustomiseClosed = true;
            Cookies.set(this.strToolboxKey + 'tipCustomiseClosed', this.tipCustomiseClosed);
        });
    }

    setupCustomiseEvents() {
        this.$btnCustomise.on('click', this.handleCustomiseClick);
        this.$btnAdd.on('click', this.handleAddClick);
        this.$btnDone.on('click', this.handleDoneClick);
    }

    setupTooltip() {
        //tips box initial state
        if (this.showTooltips) {
            this.$cbShowTooltips.prop('checked', true);
            this.$cbShowTooltips.next('.dfes-pseudo-checkbox').addClass('checked');
            this.$el.removeClass('-hide-tooltips');
        } else {
            this.$cbShowTooltips.prop('checked', false);
            this.$cbShowTooltips.next('.dfes-pseudo-checkbox').removeClass('checked');
            this.$el.addClass('-hide-tooltips');
        }
    }

    setupTooltipEvents() {
        //Listeners
        this.$cbShowTooltips.on('change', this.handleToggleTooltips);
    }

    handleToggleTooltips = (ev) => {
        this.showTooltips = this.$cbShowTooltips.prop('checked');
        if (this.showTooltips) {
            this.$el.removeClass('-hide-tooltips');
        } else {
            this.$el.addClass('-hide-tooltips');
        }
    };

    handleCustomiseClick = (ev) => {
        fadeSmoother.fadeOut(this.$btnCustomise);
        fadeSmoother.fadeOut(this.$tipToolbox);

        $('.info').popover('hide'); //Clear the popovers for mobile

        this.$tipCustomise.find('.callout-box__content').html(this.tipCustomiseDefault); //Restore the customise message, incase the 'no items' message was triggered.
        this.$el.find('a').attr('tabindex', '-1'); //disable tabbing to links

        this.enableSorting();

        setTimeout(() => {
            this.$el.addClass('-customising');
            fadeSmoother.fadeIn(this.$btnAddWrap);
            fadeSmoother.fadeIn(this.$lower);
            if (!this.tipCustomiseClosed) {
                fadeSmoother.fadeIn(this.$tipCustomise);
            }
        }, 400);
    };

    handleAddClick = (ev) => {
        this.$btnAdd.toggleClass('-close');
        $('.info').popover('hide'); //Clear the popovers for mobile

        if (this.$btnAdd.hasClass('-close')) {
            fadeSmoother.fadeIn(this.$remaining);
            this.$btnAdd.find('span').text('Close');
        } else {
            fadeSmoother.fadeOut(this.$remaining);
            this.$btnAdd.find('span').text('Add more');
        }
    };

    handleDoneClick = (ev) => {
        $('.info').popover('hide'); //Clear the popovers for mobile

        //Check for an empty toolbox & show the add if needed
        if (this.$el.children('ul').children('li').length === 0) {
            this.tipCustomiseClosed = false;
            this.$tipCustomise.find('.callout-box__content').text(this.noItemsMessage);
            fadeSmoother.fadeIn(this.$tipCustomise);
            if (!this.$btnAdd.hasClass('-close')) {
                this.handleAddClick();
            }
            return;
        }

        // Stop customising
        if (this.isModalVersion) {
            fadeSmoother.fadeOut(this.$btnAddWrap);
            fadeSmoother.fadeOut(this.$remaining);
            fadeSmoother.fadeOut(this.$tipCustomise);
            fadeSmoother.fadeOut(this.$lower);

            this.$el.find('a').removeAttr('tabindex'); //enable tabbing to links
            this.$el.removeClass('-customising');

            this.disableSorting();

            setTimeout(() => {
                fadeSmoother.fadeIn(this.$btnCustomise);
                this.$btnAdd.removeClass('-close');
                this.$btnAdd.find('span').text('Add more');
                if (!this.tipToolboxClosed) {
                    fadeSmoother.fadeIn(this.$tipToolbox);
                }
            }, 400);
        } else {
            //Modal toggle off on done
            this.$el.parents('.modal').modal('toggle');
            fadeSmoother.fadeOut(this.$remaining);
            this.$btnAdd.find('span').text('Add more');
            this.$btnAdd.removeClass('-close');
            if (!this.tipToolboxClosed) {
                fadeSmoother.fadeIn(this.$tipToolbox);
            }
        }

        //Trigger save logic
        this.save();
    };

    enableSorting() {
        this.$el.find('ul').sortable('enable');
        this.$el.find('li').attr('tabindex', '0');
    }

    disableSorting() {
        this.$el.find('ul').sortable('disable');
        this.$el.find('li').removeAttr('tabindex');
    }

    getCurrentRenderedItems() {
        //Helper for save and update
        var items = [];
        this.$el
            .children('ul')
            .children('li')
            .each(function (index) {
                items.push({
                    Id: $(this).data('id'),
                    SortOrder: index,
                });
            });
        return items;
    }

    save() {
        var saveItems = this.getCurrentRenderedItems();

        //Api call
        try {
            userStateManager.getUserState((data) => {
                //Save against the key
                data[this.strToolboxKey].ToolboxItems = saveItems;
                data.ShowTooltips = this.showTooltips;
                // console.log(data);

                userStateManager.saveUserState(data, function (respObj) {
                    // console.log('User state saved. Response:');
                    // console.log(respObj);
                });
            });
        } catch (err) {
            console.warn('There was a problem updating the user state after customising a toolbox: ' + err);
        }
    }
}
